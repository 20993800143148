#header {
  position: sticky;
}

#modal {
  position: fixed;
  overflow: hidden;
}

#sidebar-menu {
  transition: all 0.6s ease-in-out;
}

#sidebar-menu__submenu {
  transition: all 0.3s ease-in-out;
}

#sidebar-menu__button > div {
  transition: opacity 0.6s;
}

@media (max-width: 996px) {
  #PlayBtn {
    width: calc(100vw - 33px) !important;
  }
}

body {
  overscroll-behavior-y: auto !important;
}
